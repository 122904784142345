import React, {useState} from "react";

import Moment from 'react-moment';
import 'moment-timezone';

import Item from "components/Item";
import Countdown from "components/Countdown";
import Subscribe from "components/Subscribe";

const Wave = (props) => {
  const { title, subTitle, isFeatured, intro, dropDate, items } = props.wave;

  const [isDropActive, setIsDropActive] = useState(true);
  // const activateDrop = () => setIsDropActive(true)
  const activateDrop = () => {}

  return (
    <div 
      className="wave"
      data-isactive={isDropActive}
      data-isfeatured={isFeatured}>
      <div className="wave__content">
        <div className="wave__date">
          <Countdown 
            date={dropDate} 
            title={title} 
            onCountdownEnd={activateDrop}/>
        </div>
        {subTitle && 
          <h3 className="wave__subtitle">{subTitle}</h3>
        }
        {intro && 
          <p className="wave__intro">{intro}</p>
        }
      </div>
      <Subscribe isDropActive={isDropActive} />
      <ul className="wave__items">
        {items.map((item, index) => {
          return <Item key={index} data={item} isActive={isDropActive} />;
        })}
      </ul>
    </div>
  );
};

export default Wave;
