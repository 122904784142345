export const load3d = `
window.SWT_WEB_LAND = true;

window.SWT_VISUALIZER_HIDE_INIT = function() {
  document.getElementById("collectible__image-container").innerHTML = '';
}

draw3d = function(args) {
  if (window.SWT_VISUALIZER) {

    if (args.type === 'model3d') {
      let collectibleImageElem = document.getElementById("collectible__image-container");
      collectibleImageElem.innerHTML = 'Loading';
    }

    setTimeout(function(){ 
      console.log('creating canvas element')
      SWT_VISUALIZER_INSTANCE = new SWT_VISUALIZER(args);
      var canvas = document.getElementById('theCanvas');
      if (canvas) {
        SWT_VISUALIZER_INSTANCE.draw(canvas);
        if (window.matchMedia('(max-device-width: 960px)').matches && args.iscard) {
          setTimeout(() => {
            SWT_VISUALIZER_INSTANCE._sceneLoader._camera.position.z = 5.25;
            SWT_VISUALIZER_INSTANCE._sceneLoader._camera.position.y = 0.5;
          }, 500);
        }
        window.SWT_VISUALIZER_HIDE_INIT();
      }
    }, 2000);
  }
};

rotateToFront = function() {
  SWT_VISUALIZER_INSTANCE.rotateToFront();
}

rotateToBack = function() {
  SWT_VISUALIZER_INSTANCE.rotateToBack();
}

flip3d = function() {
  if (SWT_VISUALIZER_INSTANCE) {
    SWT_VISUALIZER_INSTANCE.rotateOnDemand();
  }
}

onVisualizerLoaded = function() {
  var args = {
    image: "https://collectible.sweet.io/series/7/image-front.png",
    reverse_image: "https://collectible.sweet.io/series/7/image-reverse.png",
    type: "model3d",
    pic_type: "animated",
    speed: "slow",
    sequence_number: "-",
    total_number: 1,
    name: "D&B 1-of-1 NFT",
    is_web_landing: true,
    height: 640,
    width: 512,
    color: "#74B8F9",
    metalness: "0.5",
    roughness: "0.5",
    model3d: "https://mediagateway.playground.sweet.io/media/series/ArR8Eeqd/media.glb",
    autorotate: true,
    autoresize_canvas: false,
    custom_configs: {
    "model": {
      "position": [0, -3, 0],
      "scale": 9,
      "glossiness": 1
    },
    "scene": {
      "background": {
        "hdr": "https://collectible.sweet.io/static/env/venice_sunset_1k.hdr",
        "shows": false,
        "loadType": "normal"
      },
      "renderer": {
        "toneMapping": "linear",
        "exposure": 1
      }
    },
    "camera": {
      "fov": 75,
      "near": 0.01,
      "far": 1000,
      "position": [0, 0, 10]
    },
    "lights": [{
      "type": "directional",
      "intensity": 2,
      "color": "0xffffff",
      "position": [0.5, 0, 0.866],
    }]
    
  }
  };
  draw3d(args);
}`