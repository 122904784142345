import React from "react";

// components
import SEO from "components/SEO";
import Header from "components/Header";
import Wave from "components/Wave";
import Footer from "components/Footer";

// assets
import "normalize.css";
import "src/styles/main.scss";

// data
import data from "content/data.json";

const DBCard = (item) => {
  const content = (item['item'])
  return (
    <div className="db__card">
      <h3>{content['title']}</h3>
      <p>{content['text']}</p>
    </div>
  )
}

const IndexPage = () => {

  const { locationsTitle, locationsText} = data.dbLocations;

  return (
    <main className="container">
      <SEO />
      <Header
        title={data.dropTitle}
        banner={data.bannerImage}
        intro={data.dropIntro}
      />
      <section className="content">
        <div className="db__container">
          {data.waves.map((wave, index) => {
            return (
              <Wave key={index} wave={wave} />
            )
          })}
          <div className="db__cards">
            {(data.dbCards).map((item, index) => {
              return (
                <DBCard item={item} key={index} />
              )
            })}
          </div>
        </div>
        <div className="db__locations">
          <h3>{data.dbLocations['title']}</h3>
          <p>{data.dbLocations['text']}</p>
        </div>
      </section>
      <div className="db__verification">
        <h2 className="db__verification-title">
          {data["dbVerification"]["title"]}
        </h2>
        <p>{data["dbVerification"]["text"]}</p>
      </div>
      <Footer />
    </main>
  );
};

export default IndexPage;
