import React from "react";
import { Helmet } from "react-helmet";

import { load3d } from "libs/load3d";

const Item = props => {
  const { title, subTitle, intro, image, url, isActive, ctaLabel } = props.data;

  let ctaButton = <span className="item__cta item__cta--tbd">Get It Soon</span>

  if (isActive) {
    ctaButton = <a className="item__cta" rel="noreferrer" target="_blank" href={url}>{ctaLabel}</a>
  }

  if (typeof window !== `undefined`) {
    const localScript = document.createElement('script');
    const scriptText = document.createTextNode(load3d);
    localScript.appendChild(scriptText);
    document.head.appendChild(localScript);
  }

  return (
    <li
      className="item"
      data-isactive={true}>
      <Helmet>
        <script defer src="https://collectible.playground.sweet.io/static/visualizer.js" onload="onVisualizerLoaded()"></script>
      </Helmet>
      <div className="item__image">
        <div id="collectible__image-container" />
        <canvas id="theCanvas"></canvas>
      </div>
      <div className="item__info">
        <h4 className="item__title">{title}</h4>
        <h5 className="item__subtitle">{subTitle}</h5>
        {intro && (
          <p className="item__intro">{intro}</p>
        )}
        {ctaButton}
      </div>
    </li>
  )
}

export default Item;