import React, { useEffect, useState } from "react";

const Countdown = props => {
  const difference = +new Date(props.date) - +new Date();

  const calculateTimeLeft = () => {
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    if (difference <=0) { 
      // props.onCountdownEnd()
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval, index) => {

    const timeAsString = (timeLeft[interval].toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })).toString();

    const timeLeft1stChar = Number(timeAsString.split("")[0]);
    const timeLeft2ndChar = Number(timeAsString.split("")[1]);

    timerComponents.push(
      <div key={index} className="countdown__digit">
        <div className="countdown__char-container">
          <span className="countdown__char" data-value={(timeLeft1stChar === 9) ? 0 : timeLeft1stChar }>
            {timeLeft1stChar}
          </span>
        </div>
        <div className="countdown__char-container">
          <span className="countdown__char" data-value={(timeLeft2ndChar === 9) ? 0 : timeLeft2ndChar }>
            {timeLeft2ndChar}
          </span>
        </div>
      </div>
    );
  });

  return (
    <>
      {timerComponents.length ? (
        <>
          <span className="wave__dropDate">{props.title} dropped in!</span>
          <div className="countdown__wrapper">
            {timerComponents}
          </div>
        </>
      ) : (
        'Coming soon!'
      )}
    </>
  );
};

export default Countdown;
