import React from "react";

const Subscribe = props => {
  return (
    <section className="subscribe">
      <h2 
        className="subscribe__title">
        {props.isDropActive ? "Get notified of future drops!" : "Get notified when they drop!"}
      </h2>
      <div id="mc_embed_signup">
        <form 
          action="https://sweet.us2.list-manage.com/subscribe/post?u=89488fb8f05ed2603a717754f&amp;id=757c643e25" 
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          noValidate>
          <div id="mc_embed_signup_scroll">
            <input
              type="email"
              name="EMAIL"
              className="email"
              id="mce-EMAIL"
              placeholder="Enter your email"
              required
            />
            <div className="mc_hidden" aria-hidden="true">
              <input
                type="text"
                name="b_89488fb8f05ed2603a717754f_757c643e25"
                tabIndex="-1"
              />
            </div>
            <input
              type="submit"
              value="Subscribe"
              name="subscribe"
              id="mc-embedded-subscribe"
              className="button"
            />
          </div>
        </form>
      </div>
    </section>
  );
};

export default Subscribe;
